import clsx from 'clsx'
import { trackEvent } from 'fathom-client'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useDebounceFetcher } from 'remix-utils/use-debounce-fetcher'

import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { FaceFrownIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { GlobeAsiaAustraliaIcon } from '@heroicons/react/24/solid'
import { useNavigate, useSearchParams } from '@remix-run/react'

import { SearchPaletteContext } from '~/contexts/SearchContext'
import type { SearchResult } from '~/routes/api+/search/_search'
import { BuyOnLoungePair } from './Mark'

export function SearchPalette() {
  const navigate = useNavigate()
  const fetcher = useDebounceFetcher<{
    hasResults: boolean
    results: {
      Airports: SearchResult[]
      Lounges: SearchResult[]
    }
  }>()

  const inputRef = useRef<HTMLInputElement>(null)
  const [hasUsedSearch, setUsedSearch] = useState(false)
  const [searchParams] = useSearchParams()
  const [query, setQuery] = useState(searchParams.get('search') || '')
  const { isOpen, setIsOpen } = useContext(SearchPaletteContext)

  // on escape should remove search param
  // on load should fetch

  function search(search: string) {
    // Don't search for less than 3 chars
    if (search.length < 3) return
    fetcher.submit(
      {
        search,
      },
      {
        method: 'GET',
        navigate: false,
        action: 'api/search',
        debounceTimeout: 300,
      },
    )
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value)
    search(event.target.value)
  }

  // Handle keyboard inputs for navigation
  function handleNavigate(value: unknown) {
    const loungeOrAirport = value as SearchResult
    if (!loungeOrAirport) return
    setIsOpen(false)

    if ('slug' in loungeOrAirport) {
      navigate({
        pathname: `/at/${loungeOrAirport.iata}/${encodeURIComponent(
          loungeOrAirport?.slug,
        )}/`,
      })
    } else {
      navigate({
        pathname: `/at/${loungeOrAirport.iata}/`,
      })
    }
  }

  function close() {
    setIsOpen(false)
  }

  // Update search params in the URL when query changes
  useEffect(() => {
    // Track the analytics event
    if (query !== '') {
      setUsedSearch(true)
    }
  }, [query])

  // Fire off analytics event if they have used search
  useEffect(() => {
    if (hasUsedSearch) {
      console.log('Directory Searched')
      trackEvent('Directory Searched')
    }
  }, [hasUsedSearch])

  // Focus the input when the dialog opens
  useEffect(() => {
    if (isOpen) {
      // Use a short timeout to ensure the dialog is fully rendered
      const timeoutId = setTimeout(() => {
        inputRef.current?.focus()
      }, 100)

      return () => clearTimeout(timeoutId)
    }
  }, [isOpen])

  return (
    <Transition show={isOpen} as={Fragment} appear>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-opacity-25 bg-stone-500" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen p-4 overflow-y-auto sm:p-6 md:p-20">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <DialogPanel className="max-w-xl mx-auto overflow-hidden transition-all transform bg-white shadow-2xl rounded-xl ring-1 ring-black ring-opacity-5">
              <fetcher.Form>
                <Combobox
                  name="search"
                  defaultValue={query}
                  onChange={handleNavigate}
                >
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-stone-400"
                      aria-hidden="true"
                    />
                    <ComboboxInput
                      ref={inputRef}
                      autoFocus
                      className="w-full h-12 pr-4 bg-transparent border-0 text-stone-900 pl-11 placeholder:text-stone-400 focus:ring-0"
                      placeholder="Search..."
                      spellCheck="false"
                      onChange={handleInputChange}
                    />
                  </div>

                  {query === '' && (
                    <div className="px-6 text-center border-t border-stone-100 py-14 sm:px-14">
                      <PaperAirplaneIcon
                        className="w-6 h-6 mx-auto text-stone-400"
                        aria-hidden="true"
                      />
                      <p className="mt-4 font-semibold text-stone-900">
                        Search airports. Book lounges in seconds.
                      </p>
                    </div>
                  )}

                  {query !== '' && fetcher.data?.hasResults ? (
                    <ComboboxOptions
                      static
                      className="pb-2 space-y-2 overflow-y-auto max-h-80 scroll-pb-2 scroll-pt-11"
                    >
                      {Object.entries(fetcher.data.results).map(
                        ([category, items]) => (
                          <li key={category} className="list-none">
                            <h2 className="bg-stone-100 px-4 py-2.5 font-semibold text-stone-900">
                              <span className="mr-2">
                                {category === 'Airports' ? '🛬' : '🛋'}
                              </span>
                              {category}
                              <span className="ml-1 font-mono text-sm text-stone-500">
                                ({items.length})
                              </span>
                            </h2>
                            <ul className="mt-2 text-stone-800">
                              {items.map((item) => (
                                <ComboboxOption
                                  key={item.id}
                                  value={item}
                                  className={({ active }) =>
                                    clsx(
                                      'cursor-default select-none px-4 py-2',
                                      active && 'bg-blue-50 text-blue-800',
                                    )
                                  }
                                >
                                  {'slug' in item ? (
                                    <span className="cursor-pointer">
                                      {item.name}
                                      <span className="ml-1 font-mono text-sm text-blue-800">
                                        ({item.iata})
                                      </span>
                                      {item.hasLoungePair ? (
                                        <BuyOnLoungePair className="inline-block h-4 ml-2" />
                                      ) : null}
                                    </span>
                                  ) : (
                                    <span className="cursor-pointer">
                                      {item.name}
                                      <span className="ml-1 font-mono text-sm text-blue-800">
                                        ({item.iata})
                                      </span>
                                      {item.hasLoungePair ? (
                                        <BuyOnLoungePair className="inline-block h-4 ml-2" />
                                      ) : null}
                                    </span>
                                  )}
                                </ComboboxOption>
                              ))}
                            </ul>
                          </li>
                        ),
                      )}
                    </ComboboxOptions>
                  ) : null}

                  {query !== '' && fetcher.state !== 'idle' && (
                    <div className="px-6 text-center border-t border-stone-100 py-14 sm:px-14">
                      <GlobeAsiaAustraliaIcon
                        className="w-6 h-6 mx-auto text-stone-400 animate-spin"
                        aria-hidden="true"
                      />
                      <p className="mt-4 font-semibold text-stone-900">
                        Searching...
                      </p>
                    </div>
                  )}

                  {query !== '' &&
                    fetcher.state === 'idle' &&
                    fetcher.data &&
                    !fetcher.data?.hasResults && (
                      <div className="px-6 text-center border-t border-stone-100 py-14 sm:px-14">
                        <FaceFrownIcon
                          className="w-6 h-6 mx-auto text-stone-400"
                          aria-hidden="true"
                        />
                        <p className="mt-4 font-semibold text-stone-900">
                          No results found
                        </p>
                        <p className="mt-2 text-stone-500">
                          LoungePair has no bookable lounges at this airport.
                          Please search for another.
                        </p>
                      </div>
                    )}
                </Combobox>
              </fetcher.Form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  )
}
